import React, {Component} from 'react';
import unique from 'array-unique';
import env from '../config/env';

class PreviewPanel extends Component {
  constructor(props) {
    super(props);

    const {convertedImages} = props;

    let blurValues = [],
      contrastValues = [];
    for (const image of convertedImages) {
      blurValues.push(image.blur);
      contrastValues.push(image.contrast);
    }

    unique(contrastValues).sort((a, b) => a - b);
    unique(blurValues).sort((a, b) => a - b);

    const blurIndex = Math.floor(blurValues.length / 2);
    const contrastIndex = Math.floor(contrastValues.length / 2);

    this.state = {
      blurIndex,
      contrastIndex,
      blurValues,
      contrastValues,
      rotate: 0
    };
  }

  // Explicitly check only for state updates to blur and contrast to prevent too many updates
  shouldComponentUpdate(nextProps, nextState) {
    return nextState.blurIndex !== this.state.blurIndex || nextState.contrastIndex !== this.state.contrastIndex || nextState.rotate !== this.state.rotate;
  }

  componentDidMount() {
    this._notifySelectedImage();
  }

  componentDidUpdate() {
    this._notifySelectedImage();
  }

  handleChangeContrast = (evt) => {
    this.setState({
      contrastIndex: parseInt(evt.target.value, 10)
    });
  };

  handleChangeBlur = (evt) => {
    this.setState({
      blurIndex: parseInt(evt.target.value, 10)
    });
  };

  handleRotateLeft = () => {
    this.setState({
      rotate: this.state.rotate - 90
    });
  };

  handleRotateRight = () => {
    this.setState({
      rotate: this.state.rotate + 90
    });
  };

  _selectedImage() {
    const selectedBlur = this.state.blurValues[this.state.blurIndex];
    const selectedContrast = this.state.contrastValues[this.state.contrastIndex];

    return this.props.convertedImages.find(
      (image) => image.blur === selectedBlur && image.contrast === selectedContrast
    );
  }

  _notifySelectedImage() {
    const selectedImage = this._selectedImage();
    this.props.onSelectedImage(selectedImage);
  }

  render() {
    const selectedImage = this._selectedImage();
    const imageClass = ((this.state.rotate / 90) % 2 === 0) ? `` : `e-section__img--rotated`;
    const rotateTransform = `translateX(-50%) translateY(-50%) rotate(${this.state.rotate}deg)`;

    const blur = selectedImage.blur;
    const contrast = selectedImage.contrast;
    const data = selectedImage.data;
    const rotate = this.state.rotate;

    return (

      <section className="e-section e-section--template-generator">
        <div className="e-section__body">
          <div className="e-section__img-wrapper">
            <img src={selectedImage.data} className={"e-section__img " + imageClass} alt="Converted" style={{transform: rotateTransform}}/>
          </div>
        </div>
        <div className="e-section__footer">
          <div className="e-toolbox e-toolbox--template-generator e-box">
            <div className="e-toolbox__group">
              <div className="e-toolbox__button-group col-xl-2 col-lg-2 col-12">
                <div className="e-toolbox__button-wrapper d-inline-block">
                  <button type="button" className="btn btn-outline-secondary" onClick={this.handleRotateLeft}><i className='fa fa-rotate-left'></i></button>
                </div>
                <div className="e-toolbox__button-wrapper d-inline-block">
                  <button type="button" className="btn btn-outline-secondary" onClick={this.handleRotateRight}><i className='fa fa-rotate-right'></i></button>
                </div>
              </div>

              <div className="e-toolbox__range-slider col-xl-2 col-lg-2 col-6">
                <div className="form-group">
                  <label htmlFor="e-range-slider--contrast">{this.props.contrastLabel}</label>
                  <input type="range" className="custom-range" value={this.state.contrastIndex} min="0" max={this.state.contrastValues.length - 1} onChange={this.handleChangeContrast} onMouseUp={this.handleChangeContrast}/>
                </div>
              </div>

              <div className="e-toolbox__range-slider col-xl-2 col-lg-2 col-6">
                <div className="form-group">
                  <label htmlFor="e-range-slider--blur">{this.props.blurLabel}</label>
                  <input type="range" className="custom-range" value={this.state.blurIndex} min="0" max={this.state.blurValues.length - 1} onChange={this.handleChangeBlur} onMouseUp={this.handleChangeBlur}/>
                </div>
              </div>

              <div className="e-toolbox__button-group col-xl-6 col-lg-6 col-12">
                <div className="e-toolbox__button-wrapper">
                  <form action={`${env.apiBaseUrl}/download`} method="post">
                    <input type="hidden" name="file_data" value={data}/>
                    <input type="hidden" name="rotate" value={rotate}/>
                    <button type="submit" className="e-toolbox__button btn btn-primary"><i className='fa fa-floppy-o e-icon--mr'></i>{this.props.saveLabel}</button>
                  </form>
                </div>

                <div className="e-toolbox__button-wrapper">
                  <form action={`${env.apiBaseUrl}/generate`} method="POST">
                    <input type="hidden" name="file_data" value={this.props.originalData}/>
                    <input type="hidden" name="blur" value={blur}/>
                    <input type="hidden" name="contrast" value={contrast}/>
                    <button type="submit" className="e-toolbox__button btn btn-primary"><i className='fa fa-file-pdf-o e-icon--mr'></i>{this.props.printLabel}</button>
                  </form>
                </div>

                <div className="e-toolbox__button-wrapper">
                  <button type="button" className="e-toolbox__button btn btn-secondary" onClick={this.props.onReupload}><i className='fa fa-refresh e-icon--mr'></i>{this.props.reuploadLabel}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default PreviewPanel;
