import React, { Component } from 'react';
import ProgressBar from './ProgressBar';
import FileUpload from 'react-fileupload';

import env from '../config/env';

class Uploader extends Component {
  state = {
    progressPercent: 0,
    selectedFile: null,
    errorMessage: null
  }

  onTextFieldClick = () => {
    this.refs.chooseAndUpload.click()
  }

  render() {
    const uploadOptions = {
      baseUrl: `${env.apiBaseUrl}/convert`,
      accept: 'image/*',
      fileFieldName: 'file',
      chooseAndUpload: true,
      wrapperDisplay: 'inline',

      uploading: (progress) => {
        if (progress.total) {
          const progressPercent = progress.loaded / progress.total * 80;
          this.setState({progressPercent});
        }
      },

      chooseFile: (files) => {
        if (files.length) {
          this.setState({selectedFile: files[0]});
        }
      },

      uploadSuccess: (resp) => {
        this.props.onUpload(this.state.selectedFile, resp);
      },

      beforeUpload: (files) => {
        return files.length;
      },

      uploadError: (err) => {
        this.setState({
          errorMessage: err.message,
          progressPercent: 0,
          selectedFile: null
        });
      }
    };

    const selectedFilename = this.state.selectedFile ? this.state.selectedFile.name : '';

    // render upload error
    let errorContainer = null;
    if (this.state.errorMessage !== null) {
      errorContainer = this.renderError();
    }

    return (
      <div>
        <div className="e-section-heading">
          <h2>{this.props.uploadLabel}</h2>
        </div>
        {errorContainer}
        <div className="custom-file">
          <FileUpload options={uploadOptions}  className="">
            <input type="text" className="custom-file-input" placeholder={this.props.uploadInputPlaceholder} readOnly={true} onClick={this.onTextFieldClick} value={selectedFilename} />
            <label className="custom-file-label" htmlFor="file" ref="chooseAndUpload">Browse</label>
          </FileUpload>
        </div>
        {this.state.selectedFile && <ProgressBar progress={this.state.progressPercent} />}
      </div>
    );
  }

  renderError(message) {
    return (
      <div className="alert alert-danger" role="alert">
        <span className="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
        <span className="sr-only">Error:</span> {this.state.errorMessage}
      </div>
    );
  }
}

export default Uploader;
