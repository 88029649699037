import React, { Component } from 'react';

class ProgressBar extends Component {
  static defaultProps = {
    progress: 0
  };

  render() {
    return (
      <div className="mt-3 progress" style={this.props.style}>
        <div className={`progress-bar bg-danger`}
          role="progressbar"
          aria-valuenow={this.props.progress}
          aria-valuemin="0"
          aria-valuemax="100"
          style={{width: this.props.progress + '%'}}>
          <span className="sr-only">{this.props.progress}% Complete</span>
        </div>
      </div>
    );
  }
}

export default ProgressBar;
