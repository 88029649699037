export default function initPlaceholders() {
  const uploadContentEl = document.getElementById('mg-upload-content-nojs');
  if (uploadContentEl) {
    uploadContentEl.remove();
  }

  const placeholderContents = {
    headerContentHtml: '',
    footerContentHtml: '',
    secondPageContentHtml: ''
  };

  const headerContentEl = document.getElementById('mg-header-content');
  if (headerContentEl) {
    placeholderContents.headerContentHtml = headerContentEl.innerHTML;
    headerContentEl.remove();
  }

  const footerContentEl = document.getElementById('mg-footer-content');
  if (footerContentEl) {
    placeholderContents.footerContentHtml = footerContentEl.innerHTML;
    footerContentEl.remove();
  }

  const secondPageContentEl = document.getElementById('mg-second-page-content');
  if (secondPageContentEl) {
    placeholderContents.secondPageContentHtml = secondPageContentEl.innerHTML;
    secondPageContentEl.remove();
  }

  return placeholderContents;
}