import React, { Component } from 'react';
import UploadView from './UploadView';
import PreView from './PreView';

const defaultState = {
    hasUpload: false,
    convertedImages: [],
    originalFile: null
};

class App extends Component {
  state = defaultState;

  static defaultProps = {
    staticContent: {
      headerContentHtml: '',
      footerContentHtml: '',
      secondPageContentHtml: ''
    }
  };

  componentWillMount() {
    document.getElementById('root').classList.remove('no-react');
  }

  componentDidMount() {
    document.body.classList.add('js');
  }

  componentWillUnmount() {
    document.body.classList.remove('js');
    document.getElementById('root').classList.add('no-react');
  }

  onUpload = (file, responseData) => {
    const { images, originalData } = responseData;
    this.setState({
      hasUpload: true,
      convertedImages: images,
      originalData
    });
  };

  onReupload = () => {
    this.setState(defaultState);
  };

  render() {
    if (this.state.hasUpload) {
      return <PreView originalData={this.state.originalData} {...this.props.staticContent} convertedImages={this.state.convertedImages} onReupload={this.onReupload} config={this.props.config} />;
    } else {
      return <UploadView onUpload={this.onUpload} {...this.props.staticContent} config={this.props.config} />;
    }
  }
}

export default App;
