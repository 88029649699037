import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';

// React itself uses Array.from so this is needed for IE-Support
import 'core-js/modules/es.symbol';
import 'core-js/modules/es.array.from';
import 'core-js/modules/es.array.iterator';
import 'core-js/modules/es.array.find';


import initPlaceholders from './initPlaceholders';

const placeholderContents = initPlaceholders();

const reactConfig = JSON.parse(document.getElementById('root').getAttribute('data-react-config'));

const theApp = <App staticContent={{...placeholderContents}} config={reactConfig} />;

ReactDOM.render(
  theApp,
  document.getElementById('root')
);
