import React, {Component} from 'react';
import PreviewPanel from './PreviewPanel';

class PreView extends Component {
  state = {
    selectedImage: null
  };

  handlePreviewPanelChange = (selectedImage) => {
    this.setState({selectedImage});
  };

  render() {
    return (
      <div className="mg-second-page">
        <PreviewPanel
          convertedImages={this.props.convertedImages}
          onReupload={this.props.onReupload}
          onSelectedImage={this.handlePreviewPanelChange}
          selectedImage={this.state.selectedImage}
          originalData={this.props.originalData}
          orientationLabel={this.props.config.orientationLabel}
          contrastLabel={this.props.config.contrastLabel}
          blurLabel={this.props.config.blurLabel}
          reuploadLabel={this.props.config.reuploadLabel}
          saveLabel={this.props.config.saveLabel}
          printLabel={this.props.config.printLabel}
        />

        <section className="" dangerouslySetInnerHTML={{__html: this.props.secondPageContentHtml}}/>
      </div>
    );

  }
}

export default PreView;