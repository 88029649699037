import React, { Component } from 'react';
import Uploader from './Uploader';

class UploadView extends Component {
  render() {
    return (
      <div className="mg-upload-view" style={this.props.style}>
        <div className="" dangerouslySetInnerHTML={{__html: this.props.headerContentHtml}} />

        <Uploader onUpload={this.props.onUpload} uploadLabel={this.props.config.uploadLabel} uploadButtonLabel={this.props.config.uploadButtonLabel} uploadInputPlaceholder={this.props.config.uploadInputPlaceholder} helpText={this.props.helpText} />

        <div className="" dangerouslySetInnerHTML={{__html: this.props.footerContentHtml}} />
      </div>
    );
  }
}

export default UploadView;




